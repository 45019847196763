<template>
  <v-card
    height="100%"
    class="py-2"
  >
    <div v-if="!loadingTabs">
      <v-tabs
        v-model="selectedTab"
        active-class="white"
        slider-color="accent"
        grow
        next-icon="mdi-arrow-right"
        prev-icon="mdi-arrow-left"
        show-arrows
        class="mx-0 mt-n1"
      >
        <v-tab
          v-for="(t, index) in actualTabs"
          :key="index"
          :disabled="t.disabled"
          @change="$emit('tabWasSelected', t); mutateTabInTemplatesStore(t)"
        >
          <div>
            <div>{{ t.text }}</div>
            <div
              style="font-size:10px;"
            >
              {{ t.title }}
            </div>
          </div>
        </v-tab>
        <v-tab-item
          v-for="(t, index) in actualTabs"
          :key="index"
        >
          <TemplateTabs
            :company="t.company"
            :group="t.group"
            :is-company-tab="t.isCompanyTab"
            :is-group-tab="t.isGroupTab"
            :user-role="t.userRole"
            :is-single-acc="t.isSingleAcc"
            :is-lavvira-tab="t.isLavviraTab"
            :is-my-tab="t.isMyTab"
            :actual-tab="t"
          />
        </v-tab-item>
      </v-tabs>
    </div>
    <!-- <LavviraTemplatesOverview v-if="selectedTab === 1" /> -->
  </v-card>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import generalMixin from '@/utils/generalMixin.js'
import TemplateTabs from './TemplateTabs.vue'
// import LavviraTemplatesOverview from './LavviraTemplatesOverview.vue'

export default {
  components: { TemplateTabs },
  mixins: [generalMixin],
  data: () => ({
    selectedTab: Number(localStorage.getItem('activeTemplatesTabIndex')) || 0,
    loadingTabs: true,
    lang: null
  }),
  computed: {
    ...mapState({
      templates: state => state.templates.templates,
      account: state => state.account.account,
      company: state => state.company.company
    }),
    actualTabs () {
      let tabs = []
      if (this.account.accountType === 'god') {
        tabs.push(
          {
            text: this.$t('common|my'),
            title: this.$t('templates|templates'),
            isCompanyTab: false,
            isGroupTab: false,
            isSingleAcc: false,
            isGodAcc: true,
            isLavviraTab: false,
            isMyTab: true
          },
          {
            text: 'Lavvira',
            title: this.$t('templates|templates'),
            isCompanyTab: false,
            isGroupTab: false,
            isSingleAcc: false,
            isGodAcc: true,
            isLavviraTab: true,
            isMyTab: false
          }
        )
      } else {
        if (!this.company) {
          tabs.push({
            text: this.$t('common|my'),
            title: this.$t('templates|templates'),
            isCompanyTab: false,
            isGroupTab: false,
            isSingleAcc: true,
            isLavviraTab: false,
            isMyTab: true
          },
          {
            text: 'Lavvira',
            title: this.$t('templates|templates'),
            isCompanyTab: false,
            isGroupTab: false,
            isSingleAcc: true,
            isLavviraTab: true,
            isMyTab: false
          })
        }
        if (this.company) {
          const userRole = this.verifyUserRole(this.company, this.account)
          // verifyUserRole => generalMixin
          if (userRole !== 'regular') {
            tabs.push({
              text: this.$t('common|my'),
              title: this.$t('templates|templates'),
              userRole: userRole,
              isCompanyTab: false,
              isGroupTab: false,
              isSingleAcc: false,
              isLavviraTab: false,
              isMyTab: true
            },
            {
              text: 'Lavvira',
              title: this.$t('templates|templates'),
              userRole: userRole,
              isCompanyTab: false,
              isGroupTab: false,
              isSingleAcc: false,
              isLavviraTab: true,
              isMyTab: false
            })
            tabs.push({
              text: this.company.companyData.companyName,
              title: this.$t('templates|templates'),
              company: this.company,
              isCompanyTab: true,
              isGroupTab: false,
              userRole: userRole,
              isSingleAcc: false,
              isLavviraTab: false,
              isMyTab: false,
              disabled: this.company.subscriptionStatus === 'unpaid' || false
            })
            if (this.company.groups && this.company.groups.length) {
              for (let i = 0; i < this.company.groups.length; i++) {
                const group = this.company.groups[i]
                const accountParticipation = group.groupMembers.findIndex(gm => gm._id === this.account._id)
                if (accountParticipation !== -1) {
                  tabs.push({
                    text: group.groupName,
                    title: this.$t('templates|templates'),
                    group: group,
                    isCompanyTab: false,
                    isGroupTab: true,
                    userRole: userRole,
                    isSingleAcc: false,
                    isLavviraTab: false,
                    isMyTab: false,
                    disabled: this.company.subscriptionStatus === 'unpaid' || false
                  })
                }
              }
            }
          } else if (userRole === 'regular') {
            tabs.push({
              text: 'Lavvira',
              title: this.$t('templates|templates'),
              isLavviraTab: true
            })
            tabs.push({
              text: this.company.companyData.companyName,
              title: this.$t('templates|templates'),
              company: this.company,
              isCompanyTab: true,
              isGroupTab: false,
              isMyTab: false,
              userRole: userRole,
              disabled: this.company.subscriptionStatus === 'unpaid' || false
            })
            if (this.company.groups && this.company.groups.length) {
              for (let i = 0; i < this.company.groups.length; i++) {
                const group = this.company.groups[i]
                const accountParticipation = group.groupMembers.findIndex(m => m._id === this.account._id)
                if (accountParticipation !== -1) {
                  tabs.push({
                    text: group.groupName,
                    title: this.$t('templates|templates'),
                    group: group,
                    isCompanyTab: false,
                    isGroupTab: true,
                    userRole: userRole,
                    isLavviraTab: false,
                    isMyTab: false,
                    disabled: this.company.subscriptionStatus === 'unpaid' || false
                  })
                }
              }
            }
          }
        }
      }
      return tabs
    }
  },
  watch: {
    selectedTab (value) {
      if (value === 0) {
        const index = this.selectedTab
        this.updateActualTabTemplates(this.actualTabs[index])
      }
      if (value || value === 0) {
        localStorage.setItem('activeTemplatesTabIndex', value)
      }
    },
    lang (value) {
      if (value) this.$emit('tabWasSelected', this.actualTabs[this.selectedTab])
    }
  },
  created () {
    this.toggleDrawer(true)
    this.emitTabSelectionToParent()

    // because of the tab slider => otherwise not centered
    this.$nextTick(() => {
      setTimeout(() => {
        this.loadingTabs = false
      }, '150')
    })
  },
  updated () {
    this.lang = localStorage.getItem('preferedLanguage')
  },
  methods: {
    ...mapActions({
      updateActualTabTemplates: 'templates/updateActualTabTemplates'
    }),
    ...mapMutations({
      toggleDrawer: 'app/toggleDrawer'
    }),
    emitTabSelectionToParent () {
      this.$emit('tabWasSelected', this.actualTabs[this.selectedTab])
    },
    mutateTabInTemplatesStore (tab) {
      this.updateActualTabTemplates(tab)
    }
  }
}

</script>
